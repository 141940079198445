import * as THREE from 'three'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js'
import GUI from 'lil-gui'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'
import holographicVertexShader from './shaders/holographic/vertex.glsl'
import holographicFragmentShader from './shaders/holographic/fragment.glsl'

/**
 * Base
 */
// Debug
const gui = new GUI()
gui.hide(false)

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Loaders
const gltfLoader = new GLTFLoader()

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(25, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 0, 5)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.minDistance = 3;
controls.maxDistance = 5;
// controls.enableRotate = false;
controls.enableDamping = true
controls.enablePan = false

/**
 * Renderer
 */
const rendererParameters = {}
rendererParameters.clearColor = '#1d1f2a'

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setClearColor(rendererParameters.clearColor)
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

gui
    .addColor(rendererParameters, 'clearColor')
    .onChange(() =>
    {
        renderer.setClearColor(rendererParameters.clearColor)
    })

/**
 * Material
 */
const materialParameters = {}
materialParameters.color = '#70c1ff'

// const polygonMaterialColor = {}
// polygonMaterialColor.color = '#ffffff'

gui
    .addColor(materialParameters, 'color')
    .onChange(() =>
    {
        material.uniforms.uColor.value.set(materialParameters.color)
    })

const material = new THREE.ShaderMaterial({
    vertexShader: holographicVertexShader,
    fragmentShader: holographicFragmentShader,
    uniforms:
    {
        uTime: new THREE.Uniform(0),
        uColor: new THREE.Uniform(new THREE.Color(materialParameters.color))
    },
    transparent: true,
    side: THREE.DoubleSide,
    depthWrite: false,
    blending: THREE.AdditiveBlending
})

const polygonPHMaterial = new THREE.MeshBasicMaterial({
    color: '#ffffff'
    // uniforms:
    // {
    //     uTime: new THREE.Uniform(0),
    //     uColor: new THREE.Uniform(new THREE.Color(polygonMaterialColor.color))
    // },
    // transparent: true,
    // side: THREE.DoubleSide,
    // depthWrite: false,
    // blending: THREE.AdditiveBlending
})

const polygonColorBlueMaterial = new THREE.MeshBasicMaterial({
    color: '#70c1ff'
    // uniforms:
    // {
    //     uTime: new THREE.Uniform(0),
    //     uColor: new THREE.Uniform(new THREE.Color(polygonMaterialColor.color))
    // },
    // transparent: true,
    // side: THREE.DoubleSide,
    // depthWrite: false,
    // blending: THREE.AdditiveBlending
})

// Earth
let earth = null
gltfLoader.load(
    './globe.glb',
    (gltf) =>
    {
        earth = gltf.scene
        earth.traverse((child) =>
        {
            if(child.isMesh)
                child.material = material

            // if(child.name == "PhilippinesPoly" || child.name == "JapanPoly")
            //     child.material = polygonPHMaterial
        })
        scene.add(earth)
    }
)

/**
 * Raycaster
 */
const raycaster = new THREE.Raycaster()

/**
 * Mouse Event
 */

const mouse = new THREE.Vector2()

window.addEventListener('mousemove', (_event) => 
{
    mouse.x = _event.clientX / sizes.width * 2 - 1
    mouse.y = - (_event.clientY / sizes.height) * 2 + 1
})

/**
 * Point of interest
 */
const points = [
    {
        position: new THREE.Vector3(1.55, 0.3, -0.6),
        element: document.querySelector('.none')
    },
    {
        position: new THREE.Vector3(1.55, 0.3, -0.6),
        element: document.querySelector('.philippines'),
    },
    {
        position: new THREE.Vector3(1.55, 0.3, -0.6),
        element: document.querySelector('.japan'),
    },
    {
        position: new THREE.Vector3(1.55, 0.3, -0.6),
        element: document.querySelector('.singapore'),
    },
    {
        position: new THREE.Vector3(1.55, 0.3, -0.6),
        element: document.querySelector('.hongkong'),
    },
    {
        position: new THREE.Vector3(1.55, 0.3, -0.6),
        element: document.querySelector('.seoul'),
    },
    {
        position: new THREE.Vector3(1.55, 0.3, -0.6),
        element: document.querySelector('.canada'),
    },
    {
        position: new THREE.Vector3(1.55, 0.3, -0.6),
        element: document.querySelector('.france'),
    },
]

/**
 * Animate
 */
const clock = new THREE.Clock()

let paused = false; // Flag to indicate whether time is paused or not
let startTime = 0; // Start time of the animation
let elapsedTime = 0; // Elapsed time since animation started

const tick = (timestamp) => {
    // Update controls
    controls.update();
    
    if (!startTime) startTime = timestamp;
    if (!paused) elapsedTime = timestamp - startTime;

    let customTime = Date.now() * 0.001;

    // Update Material
    material.uniforms.uTime.value = clock.getElapsedTime();

    // Earth Rotation (adjusted speed)
    if (earth) {
        earth.rotation.y = elapsedTime * 0.00025; // Adjust rotation speed here

        // Cast a ray
        raycaster.setFromCamera(mouse, camera);

        if (earth) {
            for (let i = 0; i < earth.children.length; i++) {
                const countriesChilds = earth.children[i];
                
                // console.log(countriesChilds.name);
                // Cast a ray
                raycaster.setFromCamera(mouse, camera);
                // Filter out objects with the name "Sketchfab_model"
                const filteredChildren = earth.children.filter(child => child.name !== "Sketchfab_model");
                // Perform raycasting on filtered objects
                const modelIntersects = raycaster.intersectObjects(filteredChildren, true);
                const modelIntersect = raycaster.intersectObject(countriesChilds, true);
            
                if (countriesChilds.name !== "Sketchfab_model") {
                    if (modelIntersects.length) {

                        // console.log(modelIntersects);
                        // Get the world position of the child object
                        const worldPosition = new THREE.Vector3();
                        countriesChilds.getWorldPosition(worldPosition);
                        // console.log("World position of the child object:", worldPosition);
                        var marginX = (worldPosition.x * window.innerWidth * 0.2); // Convert decimal to pixels
                        var marginY = (worldPosition.y * window.innerWidth * 0.4 / 9.8) + '%'; // Convert decimal to pixels
        
                        if(marginX <= 20)
                        {
                            marginX = '90px'
                        } else {
                            marginX = marginX + 'px'
                        }
                        
                        points[i].element.style.marginLeft = marginX;
                        
                        
                        
                        pauseAnimation();

                        if(modelIntersect.length){
                            if(countriesChilds.name == 'PhilippinesPoly'){
                                // console.log(countriesChilds.material);
                                // countriesChilds.material.color.set(0xff0000);
                                points[i].element.style.marginTop = "8.951950749572442%";
                                countriesChilds.scale.set(0.07, 0.07, 0.07);
                                countriesChilds.material = polygonColorBlueMaterial
                                // polygonMaterial.uniforms.uColor.value = new THREE.Color(polygonMaterial.color);
                                points[i].element.classList.add('ph-visible');
                            } else if (countriesChilds.name == 'JapanPoly'){
                                points[i].element.style.marginTop = "8.951950749572442%";
                                countriesChilds.scale.set(0.07, 0.07, 0.07);
                                countriesChilds.material = polygonColorBlueMaterial
                                // polygonMaterial.uniforms.uColor.value = new THREE.Color(polygonMaterial.color);
                                points[i].element.classList.add('jp-visible');
                            } else if (countriesChilds.name == 'SingaporePoly'){
                                points[i].element.style.marginTop = "8.951950749572442%";
                                countriesChilds.scale.set(0.07, 0.07, 0.07);
                                countriesChilds.material = polygonColorBlueMaterial
                                // polygonMaterial.uniforms.uColor.value = new THREE.Color(polygonMaterial.color);
                                points[i].element.classList.add('sg-visible');
                            } else if (countriesChilds.name == 'HongkongPoly'){
                                points[i].element.style.marginTop = "8.951950749572442%";
                                countriesChilds.scale.set(0.07, 0.07, 0.07);
                                countriesChilds.material = polygonColorBlueMaterial
                                // polygonMaterial.uniforms.uColor.value = new THREE.Color(polygonMaterial.color);
                                points[i].element.classList.add('hk-visible');
                            } else if (countriesChilds.name == 'SeoulPoly'){
                                points[i].element.style.marginTop = "8.951950749572442%";
                                countriesChilds.scale.set(0.07, 0.07, 0.07);
                                countriesChilds.material = polygonColorBlueMaterial
                                // polygonMaterial.uniforms.uColor.value = new THREE.Color(polygonMaterial.color);
                                points[i].element.classList.add('kr-visible');
                            } else if (countriesChilds.name == 'CanadaPoly'){
                                points[i].element.style.marginTop = "8.951950749572442%";
                                countriesChilds.scale.set(0.07, 0.07, 0.07);
                                countriesChilds.material = polygonColorBlueMaterial
                                // polygonMaterial.uniforms.uColor.value = new THREE.Color(polygonMaterial.color);
                                points[i].element.classList.add('ca-visible');
                            } else if (countriesChilds.name == 'FrancePoly'){
                                points[i].element.style.marginTop = "8.951950749572442%";
                                countriesChilds.scale.set(0.07, 0.07, 0.07);
                                countriesChilds.material = polygonColorBlueMaterial
                                // polygonMaterial.uniforms.uColor.value = new THREE.Color(polygonMaterial.color);
                                points[i].element.classList.add('fr-visible');
                            }
                        }
                        
                    } else {
                        countriesChilds.material = polygonPHMaterial
                        polygonPHMaterial.color = new THREE.Color('#ffffff')
                        points[i].element.classList.remove('ph-visible');
                        points[i].element.classList.remove('jp-visible');
                        points[i].element.classList.remove('sg-visible');
                        points[i].element.classList.remove('hk-visible');
                        points[i].element.classList.remove('kr-visible');
                        points[i].element.classList.remove('ca-visible');
                        points[i].element.classList.remove('fr-visible');
                        resumeAnimation();
                        countriesChilds.scale.set(0.04, 0.04, 0.04);
                        // polygonMaterial.uniforms.uColor.value = new THREE.Color(materialParameters.color);
                    }
                }
            }
        }
    }

    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
}

tick(); // Start the animation loop

// Function to pause the animation
const pauseAnimation = () => {
    paused = true;
}

// Function to resume the animation
const resumeAnimation = () => {
    paused = false;
    startTime = performance.now() - elapsedTime; // Adjust start time to resume from the same point
}
