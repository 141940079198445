uniform float uTime;
uniform vec3 uColor;

varying vec3 vPosition;
varying vec3 vNormal;
varying vec2 vUv;

void main()
{
    // Normal
    vec3 normal = normalize(vNormal);
    if(!gl_FrontFacing)
        normal *= -1.0;

    // Stripes
    float stripes = mod((vPosition.y - uTime * 0.3) * 2.0, 1.0);
    // float stripes = mod((vPosition.y - uTime * 0.01) * 20.0, 1.0);
    // float stripes = step(0.0, cnoise(vUv.x * 10.0));
    stripes = pow(stripes, 3.0);

    // Fresnel
    vec3 viewDirection = normalize(vPosition - cameraPosition) + 0.1;
    float fresnel = dot(viewDirection, normal) + 1.0;
    fresnel = pow(fresnel, 1.0);

    // Falloff
    float falloff = smoothstep(1.0, 0.0, fresnel);

    // Holographic
    float holographic = stripes * fresnel;
    holographic += fresnel * 1.25;
    holographic *= falloff * 1.25;

    // Final Color
    gl_FragColor = vec4(uColor, holographic);
    // gl_FragColor = vec4(vNormal, 1.0);

    #include <tonemapping_fragment>
    #include <colorspace_fragment>
}